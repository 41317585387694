import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { OutboundLink } from 'gatsby-plugin-gtag';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Full HD Camera with a blurry image",
  "path": "/Frequently_Asked_Question/Full_HD_Blurry_Image/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "Full HD Camera with a blurry image",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - Full HD Camera with a blurry image' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='Full HD Camera with a blurry image' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Full_HD_Blurry_Image/' locationFR='/fr/Frequently_Asked_Question/Full_HD_Blurry_Image/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "ftp-upload-unreliable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ftp-upload-unreliable",
        "aria-label": "ftp upload unreliable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP Upload Unreliable`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: The image of my IN-8015 or IN-9008 Full HD is out of focus.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: You can adjust the focus of the lenses of the Full HD cameras at the installation site to get the best picture sharpness:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9008_HD/Lense_Adjustment/"
        }}>{`IN-9008 FHD Objektivjustage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8015_HD/Lense_Adjustment/"
        }}>{`IN-8015 FHD Objektivjustage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8003_HD/Lense_Adjustment/"
        }}>{`IN-8003 FHD Objektivjustage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8001_HD/Lense_Adjustment/"
        }}>{`IN-8001 FHD Objektivjustage`}</a></li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`UPDATE`}</strong>{`: Check out the `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/WebUI_WDR_Schedule/"
        }}>{`WDR Time Schedule`}</a></p>
    </blockquote>
    <p>{`Furthermore, the Full HD HDD WDR mode may blur the picture when the camera is backlit. If the camera can not be repositioned, it is recommended to disable the hardware WDR mode and instead use the software WDR and adjust the brightness setting. These settings can be found in the multimedia menu of the web interface: `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Image/"
      }}>{`Image Settings`}</a>{`.`}</p>
    <p>{`You can also find a discussion of the advantages and disadvantages of the different modes in our `}<OutboundLink href="https://forum.instar.de/forum/instar-kameras/in-8015-full-hd/39483-unsere-erfahrung-mit-der-in-8015-full-hd?p=39610#post39610" mdxType="OutboundLink">{`CUSTOMER FORUM`}</OutboundLink>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      